const hotLinks = [{
  image: require('@/assets/images/screenshots/phoxDash.png'),
  imageSize: '200',
  title: 'Phox Dash',
  href: 'https://dash.pharmaguide.ca/',
  desc: 'Actionable Analytics',
  tourTarget: 'homeFeatureCardA',
},
{
  image: require('@/assets/images/screenshots/phoxWiki.png'),
  href: 'https://wiki.pharmaguide.ca/',
  imageSize: '200',
  title: 'Phox Wiki',
  desc: 'Pharmacy Resources',
  tourTarget: 'homeFeatureCardB',
},
{
  image: require('@/assets/images/screenshots/referafriendsmall.png'),
  href: '/user/refer',
  imageSize: '200',
  title: 'Refer a Friend',
  desc: 'Get a month Free!',
  tourTarget: 'homeFeatureCardC',
},
]

export default hotLinks
