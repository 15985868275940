const dashboards = [
  {
    image: require('@/assets/images/icons/dashboard-medscheck.png'),
    category: 'prod',
    imageSize: '80',
    title: 'Medscheck',
    desc: '',
    page: 'dashboard-medscheck',
  },
  {
    image: require('@/assets/images/icons/dashboarddocument.png'),
    category: 'prod',
    imageSize: '80',
    title: 'Documents',
    desc: '',
    page: 'dashboard-document',
  },
  {
    image: require('@/assets/images/icons/dashboard-rx.png'),
    category: 'prod',
    imageSize: '80',
    title: 'Prescriptions',
    desc: '',
    page: 'dashboard-rx',
  },
  {
    image: require('@/assets/images/icons/dashboard-logs.png'),
    category: 'prod',
    imageSize: '80',
    title: 'Logs',
    desc: '',
    page: 'dashboard-logs',
  },
  {
    image: require('@/assets/images/icons/dashboard-mixtures.png'),
    category: 'prod',
    imageSize: '80',
    title: 'Recent Compounds',
    desc: '',
    page: 'dashboard-mixtures',
  },
]

export default dashboards
