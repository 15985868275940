<template>
  <section id="dashboard-view-base">
    <!-- seach banner  -->
    <v-card
      flat
      class="dashboard-view-base-bg d-flex align-center justify-center text-center mb-7"
    >
      <v-card-text id="patientViewFile">
        <v-form class="kb-search-input mx-auto">
          <v-text-field
            v-model="knowledgeBaseSearchQuery"
            outlined
            placeholder="Search for Dashboard..."
            hide-details
            class="kb-search-input"
          >
            <template #prepend-inner>
              <v-icon
                size="23"
                class="mx-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- kb search content -->
    <div id="dashboard-view-base-content">
      <v-row class="kb-search-content-info match-height">
        <v-col
          v-for="item in filteredKB"
          v-show="item.category == 'prod' || (item.category == 'dev' && ($store.state.User.pharmacy._id == '5b539d500d356d3568fddad2') || $store.state.User.pharmacy._id == '5c86a701e18d087fc087192f')"
          :key="item.image"
          md="4"
          sm="6"
          cols="12"
          class="kb-search-content"
        >
          <v-card
            class="hover:tw-bg-gray-400 hover:tw-bg-opacity-5"
            color="text-center cursor-pointer"
            @click.native="$router.push({
              name: item.page,
            }).catch(()=>{})"
          >
            <div class="tw-flex tw-justify-start">
              <div
                v-if="item.Status"
                class="tw-badge tw-badge-info"
              >
                {{ item.Status }}
              </div>
            </div>
            <div class="kb-image-wrapper">
              <v-img
                contain
                :max-width="item.imageSize"
                class="mx-auto align-center justify-center"
                :src="item.image"
              ></v-img>
            </div>
            <!-- title -->

            <v-card-title class="justify-center pb-3 tw-break-normal">
              {{ item.title }}
            </v-card-title>
            <v-card-text>
              {{ item.desc }}
            </v-card-text>
          </v-card>
        </v-col>

        <!-- no result found -->
        <v-col
          v-show="!filteredKB.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            Search result not found!!
          </h4>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
import { mdiMagnify, mdiAccountHeart, mdiPill } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { mapGetters, mapState } from 'vuex'
import { dashboards } from '@/assets/cardItems/index'

export default {
  name: 'Patientforms',
  computed: {
    ...mapGetters(['UserProfile', 'PharmacyProfile', 'PatientProfile']),
    ...mapState(['User'], ['PatientProfileStore']),
  },
  components: {},
  data() {
    return {
      dashboards,
      user: this.$auth.user,
      patientFullName: '',
      groupList: [],
      groupPatientList: [],
      selectedGroup: undefined,
    }
  },
  setup() {
    const knowledgeBaseSearchQuery = ref('')

    const filteredKB = computed(() => {
      const knowledgeBaseSearchQueryLower = knowledgeBaseSearchQuery.value.toLowerCase()

      return dashboards.filter(
        item =>
          // eslint-disable-next-line operator-linebreak
          item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) ||
          item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower),
      )
    })

    return {
      knowledgeBaseSearchQuery,
      filteredKB,
      icons: { mdiMagnify, mdiAccountHeart, mdiPill },
    }
  },
  async mounted() {
    this.changeNameCase()
    this.groupList = await this.getGroupList()
    this.$root.$on('Refresh_Status_Bar', patientID => {
      this.changeNameCase()
      this.preloadForm()

      // this.updateIntercom()
    })
    this.$root.$on('Refresh_Table', patientID => {
      this.changeNameCase()
      this.preloadForm()

      // this.updateIntercom()
    })
  },
  methods: {
    async getGroupList() {
      try {
        return (await this.$root.$system.DBAdapterConn.getGroupList()).map(row => ({
          text: row.Name,
          value: row.GroupID,
        }))
      } catch (e) {
        throw new Error(e)
      }
    },
    capitalizeFirstLetter(str) {
      return str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1)
    },
    changeNameCase() {
      if (this.PatientProfile['FIRST NAME'] != null) {
        this.patientFullName = `${this.capitalizeFirstLetter(
          this.PatientProfile['FIRST NAME'],
        )} ${this.capitalizeFirstLetter(this.PatientProfile['LAST NAME'])}`
      }
    },
    preloadForm() {
      this.patientData = {
        ...this.$store.state.Patient.data,
      }
      this.medicationsList = this.$store.state.Patient.medications
      this.patientProfile = {
        ...this.$store.state.Patient.data,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/dashboardHub.scss';
#patientViewFile {
  span.font-weight-black.text-no-wrap {
    font-weight: 900 !important;
    font-size: 24px !important;
  }
}
</style>
