const quickLinks = [{
  image: require('@/assets/images/icons/formHub.png'),
  imageSize: '30',
  title: 'Form Hub',
  page: 'formhub',
  desc: 'Here you can find all the forms you need',
  tourTarget: 'formHub',
},
{
  image: require('@/assets/images/icons/dasboardHub.png'),
  page: 'dashboard-hub',
  imageSize: '30',
  title: 'Dashboards',
  desc: 'Dashboads are powerful data Tables that allow you to access and filter data',
  tourTarget: 'dashboardHub',
},
{
  image: require('@/assets/images/icons/groupHub.png'),
  page: 'grouphub-groupforms',
  imageSize: '30',
  title: 'Group Hub',
  desc: 'Introuducing Group Hub, Now you can manage entire groups with just a few clicks',

  //   Status: 'Coming Soon',
  tourTarget: 'groupHub',
},
]

export default quickLinks
